.simplebar-mask {
  position: initial;
}

.simplebar-scrollbar:before {
  background: #c2cad4;
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: 1;
}

.simplebar-track.simplebar-horizontal {
  height: 8px;
  background: #ecedef;
  border-radius: 6px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  left: 0;
  right: 0;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  height: 8px;
  top: 0;
}

.simplebar-content-wrapper {
  height: 100% !important;
}
